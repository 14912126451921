export const initialState = {
    user: null,
    playlists: [],
    spotify: null,
    playing: false,
    item: null,
    songlists: null,
    playlistname: null,
    playlistdesc: null,
    top_artists: null,
    volumn: 75,
    shuffle: null,
    repeat: null,
    token: null,
    // REMOVE AFTER DEVELOPING
    // token: 'BQDjBH_7PzcMJn7KWeqcAxVHah1Cw01kEVmfySsbwmNJACTqN-FsWgppRd5SJwHZ6B2WD0yv2p4uYSmGywaPe81E1cXjla9XkJ6T5yX9w2cZ69blMwlGoqIdcfSx6-Zn6mnhah7JoQ3dpeHPe-73HH2dioe_a3Xs3l1SJfNzvBu7vbtZ4HSy',
};

const reducer = (state, action) => {

    // Action -> type, [payload]

    switch( action.type ) {
        case 'SET_PLAYLIST_NAME': 
            return {
                ...state, 
                playlistname: action.playlistname,
            }
        case 'SET_PLAYLIST_DESC': 
            return {
                ...state, 
                playlistdesc: action.playlistdesc,
            }
        case 'SET_VOLUMN': 
            return {
                ...state, 
                volumn: action.volumn,
            }
        case 'SET_SHUFFLE': 
            return {
                ...state, 
                shuffle: action.shuffle,
            }
        case 'SET_REPEAT': 
            return {
                ...state, 
                repeat: action.repeat,
            }
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.token
            }
        case 'SET_PLAYLISTS':
            return {
                ...state,
                playlists: action.playlists
            }
        case 'SET_TOP_ARTISTS':
            return {
                ...state,
                top_artists: action.top_artists
            }
        case 'SET_SONGLISTS':
            return {
                ...state,
                songlists: action.songlists
            }
        case 'SET_ITEM':
            return {
                ...state,
                item: action.item
            }
        case "SET_PLAYING":
            return {
                ...state,
                playing: action.playing,
            };
        case "SET_SPOTIFY":
            return {
            ...state,
            spotify: action.spotify,
            };
        default:
            return state;
    }
}

export default reducer;