import React from 'react'
import './css/Body.css'
import Header from './Header';
import SongRow from './SongRow';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MoreHorizonIcon from '@material-ui/icons/MoreHoriz';
import { useDataLayerValue } from './DataLayer';

function Body({spotify}) {
    const [{ songlists, playlistname, playlistdesc }, dispatch] = useDataLayerValue();
    
    const playPlaylist = (id) => {
        spotify
            .play({
                context_uri: `spotify:playlist:37i9dQZEVXcJbDjdHX2PV8`,
            })
            .then((res) => {
                spotify.getMyCurrentPlayingTrack().then((res) => {
                    dispatch({
                        type: "SET_ITEM",
                        item: res.item,
                    });
                    dispatch({
                        type: "SET_PLAYING",
                        playing: true,
                    });
                });
            });
        };

    const playSong = (id) => {
        // console.log(id);
        spotify
            .play({
                uris: [`spotify:track:${id}`],
            })
            .then((res) => {
                spotify.getMyCurrentPlayingTrack().then((res) => {
                    dispatch({
                        type: "SET_ITEM",
                        item: res.item,
                    });
                    dispatch({
                        type: "SET_PLAYING",
                        playing: true,
                    });
                });
        });
    };

    return (
        <div className="body">
            <Header spotify={spotify} />

            <div className="body__info">
                <img src={songlists?.images[0].url} alt="DW" />
                <div className="body__infoText">
                    <strong>PLAYLIST</strong>
                    <h2>{ playlistname }</h2>
                    <p>{ playlistdesc }</p>
                </div> 
            </div>
            
            <div className="body__songs">
                <div className="body__icons">
                    <PlayCircleFilledIcon onClick={playPlaylist} className="body__shuffle"/>
                    <FavoriteIcon fontSize="large"/>
                    <MoreHorizonIcon />
                </div>

                {songlists?.tracks.items.map( (item) => (
                    <SongRow playSong={playSong} track={item.track} key={item.track.id}/>
                ))}

           </div>

        </div>
    )
}

export default Body
