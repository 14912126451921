import React, { useEffect } from 'react'
import './css/Footer.css';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { Grid, Slider } from '@material-ui/core';
import { useDataLayerValue } from './DataLayer';

function Footer({spotify}) {
    const [{item, playing, shuffle, repeat }, dispatch] = useDataLayerValue();
    
    spotify.setVolume(75);
    
    useEffect(() => {
        spotify.getMyCurrentPlaybackState().then((res) => {
    
            dispatch({
                type: "SET_PLAYING",
                playing: res.is_playing,
            });
    
            dispatch({
                type: "SET_ITEM",
                item: res.item,
            });
        });
    }, [spotify, dispatch]);
    
    const handlePlayPause = () => {
        if (playing) {
            spotify.pause();
            dispatch({
                type: "SET_PLAYING",
                playing: false,
            });

        } else {
            spotify.play();
            dispatch({
                type: "SET_PLAYING",
                playing: true,
            });
        }
    };

    const handleShuffle = () => {
        if (shuffle) {
            spotify.setShuffle(false);

            dispatch({
                type: "SET_SHUFFLE",
                shuffle: false
            });
        } else {
            spotify.setShuffle(true);

            dispatch({
                type: "SET_SHUFFLE",
                shuffle: true
            });
        }
    }

    const handleRepeat = () => {
        if (repeat) {
            spotify.setRepeat("off");

            dispatch({
                type: "SET_REPEAT",
                repeat: false
            });
        } else {
            spotify.setRepeat("track");

            dispatch({
                type: "SET_REPEAT",
                repeat: true
            });
        }
    }

    const handleVolume = () => {
        let volumnVal = document.getElementsByClassName('MuiSlider-thumb')[0].ariaValueNow;
        if(volumnVal !== 'NaN') {
            spotify.setVolume(volumnVal);
            
            dispatch({
                type: "SET_VOLUMN",
                volumn: volumnVal
            });
        }

    }
    
    const skipNext = () => {
        spotify.skipToNext();
        spotify.getMyCurrentPlayingTrack().then((res) => {
            dispatch({
                type: "SET_ITEM",
                item: res.item,
            });
            dispatch({
                type: "SET_PLAYING",
                playing: true,
            });
        });
    };
    
    const skipPrevious = () => {
        spotify.skipToPrevious();
        spotify.getMyCurrentPlayingTrack().then((res) => {
            dispatch({
                type: "SET_ITEM",
                item: res.item,
            });
            dispatch({
                type: "SET_PLAYING",
                playing: true,
            });
        });
      };

    return (
        <div className="footer">
            <div className="footer__left">
                <img 
                    className="footer__albumLogo" alt={item?.name} src={item?.album.images[0].url}  />

                {item ? (
                    <div className="footer__songInfo">
                        <h4>{item.name}</h4>
                        <p>{item.artists.map((artist) => artist.name).join(", ")}</p>
                    </div>
                ) : (
                    <div className="footer__songInfo">
                        <h4>No song is playing</h4>
                        <p>...</p>
                    </div>
                )}
                
            </div>
            <div className="footer__center">
                {shuffle? (
                    <ShuffleIcon onClick={handleShuffle} className="footer__green" />
                ) : (
                    <ShuffleIcon onClick={handleShuffle} />
                ) }
                <SkipPreviousIcon onClick={skipPrevious} className="footer__icon" />
                {playing? (
                    <PauseCircleOutlineIcon onClick={handlePlayPause} fontSize="large" className="footer__icon" />
                    ) : (
                    <PlayCircleOutlineIcon onClick={handlePlayPause} fontSize="large" className="footer__icon" />
                )}
                <SkipNextIcon onClick={skipNext} className="footer__icon" />
                {repeat? (
                    <RepeatIcon onClick={handleRepeat} className="footer__green" />
                ) : (
                    <RepeatIcon onClick={handleRepeat} />
                )}
            </div>
            <div className="footer__right">
                <Grid container spacing={2}>
                    <Grid item>
                        <PlaylistPlayIcon />
                    </Grid>
                    <Grid item>
                        <VolumeDownIcon />
                    </Grid>
                    <Grid item xs>
                        <Slider max={100} min={0} defaultValue={75} onChange={handleVolume} aria-labelledby="continuous-slider" />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Footer
