import React from 'react'
import './css/SidebarOption.css'
import { useDataLayerValue } from './DataLayer';

function SidebarOption({ title, Icon, keyId }) {
    const [ { spotify, token }, dispatch ] = useDataLayerValue();
    
    const handleClick =() => {

        if( keyId !== null ) {
            spotify.getPlaylist(keyId).then((response) =>{
                dispatch({
                  type: 'SET_SONGLISTS',
                  songlists: response
                });
                
                dispatch({
                    type: 'SET_PLAYLIST_NAME',
                    playlistname: response.name
                });

                dispatch({
                    type: 'SET_PLAYLIST_DESC',
                    playlistdesc: response.description
                });
              });
        } 
        else {  /* MEANING IT WILL BE EITHER HOME,  */
            if( title === 'Your Library' ){
                console.log(spotify.getMySavedAlbums(token));
            }
        }

    }

    return (
        <div className="sidebarOption" onClick={handleClick}>
            {Icon && <Icon className="sidebarOption__icon" />}
            {Icon ? <h4>{title}</h4> :<p>{title}</p>}
        </div>
    )
}

export default SidebarOption
