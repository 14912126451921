import React, { useEffect } from 'react';
import './App.css';
import Login from './Login';
import { getTokenFromUrl } from './spotify';
import SpotifyWebApi from 'spotify-web-api-js';
import Player from './Player';
import { useDataLayerValue } from './DataLayer';

const spotify = new SpotifyWebApi();
console.log(spotify);
function App() {
  const [{ token }, dispatch] = useDataLayerValue();

  // Run code based on a given condition
  useEffect(function() {
    const hash = getTokenFromUrl();
    window.location.hash = "";
    const _token = hash.access_token; 

    if(_token){
      spotify.setAccessToken(_token);

      dispatch({
        type: 'SET_TOKEN',
        token: _token,
      });

      spotify.getMe().then((user) => {
        dispatch({
          type: 'SET_USER',
          user: user,
        });
      });

      spotify.getUserPlaylists().then((playlists) => {
        dispatch({
          type: 'SET_PLAYLISTS',
          playlists: playlists
        });
      });

      // www.spotify.com/discoverweekly (for first load)
      spotify.getPlaylist('37i9dQZEVXcJbDjdHX2PV8').then((response) =>{
        dispatch({
          type: 'SET_SONGLISTS',
          songlists: response
        });
                
        dispatch({
            type: 'SET_PLAYLIST_NAME',
            playlistname: response.name
        });

        dispatch({
            type: 'SET_PLAYLIST_DESC',
            playlistdesc: response.description
        });
      });

      spotify.getMyTopArtists().then((response) =>
        dispatch({
          type: "SET_TOP_ARTISTS",
          top_artists: response,
        })
      );

      
      dispatch({
        type: "SET_SPOTIFY",
        spotify: spotify,
      });
    }

  }, [token, dispatch]);

  return (
    // BEM
    <div className="App">
      {
        token ? (<Player spotify={spotify}/>) : ( <Login /> )
      }
   
      
    </div>
  );
}

export default App;
